import CroatianSVG from '../../assets/icons/croatian.svg';
import EnglishSVG from '../../assets/icons/english.svg';

interface IconScheme {
  className?: string
}

const LogoutIcon = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.5H1M1 4.5L5 1M1 4.5L5 8" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const StepBackIcon = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3905 0.687656C11.563 0.903287 11.528 1.21793 11.3124 1.39044L6.00004 5.64032L0.687696 1.39044C0.472064 1.21793 0.437103 0.903287 0.609609 0.687656C0.782113 0.472025 1.09676 0.437064 1.31239 0.609569L6.00004 4.35969L10.6877 0.609569C10.9033 0.437064 11.218 0.472025 11.3905 0.687656Z" fill="white" fillOpacity="0.7"/>
  </svg>
);

const ClockIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 14C11.0899 14 14 11.0898 14 7.49998C14 3.91013 11.0899 0.999985 7.5 0.999985C3.91015 0.999985 1 3.91013 1 7.49998C1 11.0898 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.49998C15 3.35785 11.6421 -1.52588e-05 7.5 -1.52588e-05C3.35786 -1.52588e-05 0 3.35785 0 7.49998C0 11.6421 3.35786 15 7.5 15Z" fill="#EEEEEE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.49998C7.77614 3.49998 8 3.72384 8 3.99998V7.3486L9.91603 10.2226C10.0692 10.4524 10.0071 10.7628 9.77735 10.916C9.54759 11.0692 9.23715 11.0071 9.08397 10.7773L7.08397 7.77734C7.02922 7.6952 7 7.5987 7 7.49998V3.99998C7 3.72384 7.22386 3.49998 7.5 3.49998Z" fill="white"/>
  </svg>
);

const SelectArrow = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1L6 5L1 1" stroke="white" strokeOpacity="0.7" strokeLinecap="round"/>
  </svg>
);

const LanguageSelectArrow = (props: IconScheme) => (
  <svg className={props.className} width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.25L3.5 3.75L6 1.25" stroke="#C2C2C2" strokeLinecap="round"/>
  </svg>
);

const BackArrow = (props: IconScheme) => (
  <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 7L1 7M1 7L6 13M1 7L6 1" stroke="#C2C2C2" strokeLinecap="round"/>
  </svg>
);

const SuccessIcon = (props: IconScheme) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#3BC279" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 12L11 14L15 10" stroke="#3BC279" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>  
);

const ErrorIcon = (props: IconScheme) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#E9594C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 15H12.0183" stroke="#E9594C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 8V12" stroke="#E9594C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ExitIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 5L5 15" stroke="#C2C2C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 5L15 15" stroke="#C2C2C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ReservationErrorIcon = (props: IconScheme) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0C22.4308 0 0 22.4288 0 50C0 77.5712 22.4308 100 50 100C77.5692 100 100 77.5712 100 50C100 22.4288 77.5692 0 50 0ZM50 96.1538C24.5519 96.1538 3.84615 75.4481 3.84615 50C3.84615 24.5519 24.5519 3.84615 50 3.84615C75.4481 3.84615 96.1538 24.5519 96.1538 50C96.1538 75.4481 75.4481 96.1538 50 96.1538Z" fill="#D12E2E"/>
    <path d="M68.6674 31.3327C67.9154 30.5808 66.7 30.5808 65.9481 31.3327L50.0001 47.2808L34.052 31.3327C33.3001 30.5808 32.0847 30.5808 31.3327 31.3327C30.5808 32.0847 30.5808 33.3001 31.3327 34.052L47.2808 50L31.3327 65.9481C30.5808 66.7 30.5808 67.9154 31.3327 68.6674C31.7077 69.0424 32.2001 69.2308 32.6924 69.2308C33.1847 69.2308 33.677 69.0424 34.052 68.6674L50.0001 52.7193L65.9481 68.6674C66.3231 69.0424 66.8154 69.2308 67.3077 69.2308C67.8 69.2308 68.2924 69.0424 68.6674 68.6674C69.4193 67.9154 69.4193 66.7 68.6674 65.9481L52.7193 50L68.6674 34.052C69.4193 33.3001 69.4193 32.0847 68.6674 31.3327Z" fill="#D12E2E"/>
  </svg>
);

const ReservationSuccessIcon = (props: IconScheme) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0C22.4308 0 0 22.4288 0 50C0 77.5712 22.4308 100 50 100C77.5692 100 100 77.5712 100 50C100 22.4288 77.5692 0 50 0ZM50 96.1538C24.5519 96.1538 3.84615 75.4481 3.84615 50C3.84615 24.5519 24.5519 3.84615 50 3.84615C75.4481 3.84615 96.1538 24.5519 96.1538 50C96.1538 75.4481 75.4481 96.1538 50 96.1538Z" fill="white"/>
    <path d="M73.5616 29.4923L44.0058 62.7423L26.2 48.4981C25.3731 47.8346 24.1596 47.9712 23.4981 48.7981C22.8346 49.6288 22.9693 50.8385 23.7981 51.5019L43.0289 66.8865C43.3827 67.1692 43.8077 67.3077 44.2308 67.3077C44.7616 67.3077 45.2904 67.0885 45.6692 66.6615L76.4385 32.0462C77.1442 31.2538 77.0731 30.0365 76.2789 29.3327C75.4827 28.625 74.2692 28.6962 73.5616 29.4923Z" fill="white"/>
  </svg>
);

const PhoneMessageIcon = (props: IconScheme) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_766_146)">
  <path d="M45.8334 12.5H87.5V45.8333H75L58.3334 54.1667V45.8333H45.8334V12.5Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M62.5 66.6667V83.3333C62.5 84.4384 62.0611 85.4982 61.2797 86.2796C60.4983 87.061 59.4384 87.5 58.3334 87.5H25C23.895 87.5 22.8352 87.061 22.0538 86.2796C21.2724 85.4982 20.8334 84.4384 20.8334 83.3333V25C20.8334 23.8949 21.2724 22.8351 22.0538 22.0537C22.8352 21.2723 23.895 20.8333 25 20.8333H33.3334" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M41.6666 75V75.0417" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_766_146">
  <rect width="100" height="100" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

const English = (props: IconScheme) => (
  <img src={EnglishSVG} alt="English"/>
);

const Croatian = (props: IconScheme) => (
  <img src={CroatianSVG} alt="Croatian"/>
);

const LanguageIcon = (props: { language: string }) => {
  if(props.language === 'en')
    return <English />
  else if(props.language === 'hr')
    return <Croatian />
  else
    return <English />
}

const InstagramIcon = (props: IconScheme) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_710_151)">
  <path d="M20 5H10C7.23858 5 5 7.23858 5 10V20C5 22.7614 7.23858 25 10 25H20C22.7614 25 25 22.7614 25 20V10C25 7.23858 22.7614 5 20 5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M20.625 9.375V9.376" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_710_151">
  <rect width="30" height="30" fill="white"/>
  </clipPath>
  </defs>
  </svg>
)

const FacebookIcon = (props: IconScheme) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_710_156)">
  <path d="M8.75 12.5V17.5H12.5V26.25H17.5V17.5H21.25L22.5 12.5H17.5V10C17.5 9.66848 17.6317 9.35054 17.8661 9.11612C18.1005 8.8817 18.4185 8.75 18.75 8.75H22.5V3.75H18.75C17.0924 3.75 15.5027 4.40848 14.3306 5.58058C13.1585 6.75269 12.5 8.3424 12.5 10V12.5H8.75Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_710_156">
  <rect width="30" height="30" fill="white"/>
  </clipPath>
  </defs>
  </svg>
)

const ButtonArrow = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.5L11 4.5M11 4.5L7 8M11 4.5L7 1" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const BottleIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.16602 4.4275V2.33333C8.16602 2.17862 8.10456 2.03025 7.99516 1.92085C7.88576 1.81146 7.73739 1.75 7.58268 1.75H6.41602C6.26131 1.75 6.11293 1.81146 6.00354 1.92085C5.89414 2.03025 5.83268 2.17862 5.83268 2.33333V4.4275C5.8323 4.95491 5.62248 5.46059 5.24935 5.83333C4.87622 6.20608 4.6664 6.71175 4.66602 7.23917V11.6667C4.66602 11.8214 4.72747 11.9697 4.83687 12.0791C4.94627 12.1885 5.09464 12.25 5.24935 12.25H8.74935C8.90406 12.25 9.05243 12.1885 9.16183 12.0791C9.27122 11.9697 9.33268 11.8214 9.33268 11.6667V7.23917C9.3323 6.71175 9.12248 6.20608 8.74935 5.83333C8.37622 5.46059 8.1664 4.95491 8.16602 4.4275Z" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const PersonIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.03696 8.86636C9.69258 8.42057 10.1903 7.77111 10.4566 7.01404C10.7229 6.25696 10.7436 5.43246 10.5156 4.66246C10.2876 3.89246 9.82301 3.21784 9.19058 2.73836C8.55816 2.25888 7.79144 2 7.00382 2C6.2162 2 5.44949 2.25888 4.81706 2.73836C4.18464 3.21784 3.72008 3.89246 3.49208 4.66246C3.26408 5.43246 3.28475 6.25696 3.55103 7.01404C3.81731 7.77111 4.31506 8.42057 4.97068 8.86636C3.78473 9.3128 2.7719 10.1389 2.08331 11.2215C2.04713 11.2764 2.02199 11.3381 2.00936 11.4031C1.99674 11.468 1.99688 11.5349 2.00978 11.5998C2.02268 11.6647 2.04808 11.7263 2.0845 11.7811C2.12092 11.8358 2.16764 11.8827 2.22194 11.9189C2.27624 11.955 2.33703 11.9798 2.4008 11.9918C2.46456 12.0037 2.53001 12.0026 2.59335 11.9885C2.65669 11.9744 2.71666 11.9476 2.76976 11.9096C2.82287 11.8717 2.86805 11.8233 2.90269 11.7673C3.34685 11.0696 3.95463 10.4962 4.67084 10.0993C5.38704 9.70238 6.18898 9.49448 7.00382 9.49448C7.81867 9.49448 8.6206 9.70238 9.33681 10.0993C10.053 10.4962 10.6608 11.0696 11.105 11.7673C11.1767 11.8762 11.2875 11.9519 11.4135 11.978C11.5395 12.0042 11.6704 11.9786 11.7781 11.9069C11.8857 11.8352 11.9614 11.7231 11.9888 11.5948C12.0161 11.4665 11.993 11.3324 11.9243 11.2215C11.2357 10.1389 10.2229 9.3128 9.03696 8.86636ZM4.31335 5.7487C4.31335 5.20521 4.47114 4.67393 4.76678 4.22203C5.06241 3.77014 5.4826 3.41793 5.97422 3.20994C6.46584 3.00196 7.00681 2.94754 7.52871 3.05357C8.05061 3.1596 8.53 3.42132 8.90627 3.80562C9.28254 4.18993 9.53879 4.67956 9.6426 5.21261C9.74641 5.74566 9.69313 6.29817 9.48949 6.80029C9.28586 7.30241 8.94101 7.73158 8.49857 8.03353C8.05612 8.33548 7.53595 8.49664 7.00382 8.49664C6.29051 8.49581 5.60665 8.20603 5.10226 7.69088C4.59788 7.17572 4.31416 6.47725 4.31335 5.7487Z" />
  </svg>
)

export {
  LanguageSelectArrow,
  StepBackIcon,
  SelectArrow,
  ButtonArrow,
  LogoutIcon,
  BackArrow,
  ClockIcon,
  LanguageIcon,
  InstagramIcon,
  FacebookIcon,
  SuccessIcon,
  ErrorIcon,
  ExitIcon,
  ReservationErrorIcon,
  ReservationSuccessIcon,
  PhoneMessageIcon,
  BottleIcon,
  PersonIcon
}