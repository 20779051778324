import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { isPhoneSubscribed, subscribePhone } from "../../helpers/api/newsletter";
import { LogoutIcon, ReservationSuccessIcon, ReservationErrorIcon, ButtonArrow, PhoneMessageIcon } from "../icons";
import { Image } from "../image";
import Loading from "../loading";
import TelInput from "./phoneInput";
import { useNavigate } from "react-router-dom";

interface FormScheme {
  onAction: (direction: string) => void,
  onReserve: (userData: { name: string, phone: string }) => void,
  selectSection: () => void,
  availableTables: number,
  currentTable: number,
  reservationsStatus: string,
  isInReservationProccess: boolean,
  reservationLink: string,
  minBottlesBadge: React.ReactNode
}

const Form = (props: FormScheme) => {
  const { selected_event } = useAppSelector( state => state.events );
  const { place } = useAppSelector( state => state.place );
  const [userData, updateUserData] = useState({ name: "", phone: "" });
  const [personalState, setPersonal] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState("no");
  const [buttonText, setButtonText] = useState("want_this_table");
  // translation
  const {t} = useTranslation(['buttons', 'inputs', 'general']);

  useEffect(() => {
    if(props.availableTables !== 0)
      setLoaded(true)
    else
      setLoaded(false)
  }, [props.availableTables])

  useEffect(() => {
    if(!('id' in selected_event))
      handleStepBack();
  }, [selected_event])

  // function to handle any input (name and surname in this case)
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    updateUserData((prevState) => ({...prevState, [name]: value}))
  }

  // function to handle main action button click
  const handleButtonClick = () => {
    // open personal
    if(!personalState) {
      setPersonal(true);
      setButtonText("reserve");
      return;
    }
    // check if all fields are filled
    if(userData.name === "" || userData.phone === "") return;
    // make a reservation
    props.onReserve(userData);
  }

  // function to handle step back click
  const handleStepBack = () => {
    // close personal
    setPersonal(false);
    setButtonText("want_this_table");
  }

  // function to handle phone input
  const handlePhoneInput = (phone: string) => {
    if(phone !== userData.phone)
      updateUserData((prevState) => ({...prevState, phone}));
  }

  const checkShouldSubscribe = async () => {
    try{
      // make API request to check if phone number is already subscribed
      let isSubscribed = await isPhoneSubscribed(userData.phone, place.slug);
      if(isSubscribed) return window.location.reload();
      // set should subscribe flag if phone is not already subscribed
      setSubscribeStatus("newsletter");
    } catch(e) {
      window.location.reload();
    } 
  }

  const subscribe = async () => {
    try {
      // subscribe phone number
      let subscribed = await subscribePhone(userData.phone, place.slug);
      if(!subscribed) window.location.reload();
      // display success message
      setSubscribeStatus("newsletter-done");
    } catch(e) {
      window.location.reload();
    }
  }

  if(props.reservationsStatus !== "unknown")
    return <div className="controller">
      <div className="form">
        <DisplayReservationStatus reservationLink={props.reservationLink} status={subscribeStatus === "no" ? props.reservationsStatus : subscribeStatus} shouldSubscribe={checkShouldSubscribe} subscribe={subscribe} />
      </div>
    </div>

  if(selected_event.event_mode === 'choose_section')
    return <div className="controller">
      <div className="controls">
        <div className="action prev" onClick={() => props.onAction("prev")}>
          <Image src="../assets/icons/back-arrow.svg" alt="" isSvg />
          <div className="control-text">{t("prev_section", {ns: 'general'})}</div>
        </div>
        <div className="min-bottles-badge">
          { props.minBottlesBadge }
        </div>
        <div className="action next" onClick={() => props.onAction("next")}>
          <div className="control-text">{t("next_section", {ns: 'general'})}</div>
          <img src="../assets/icons/back-arrow.svg" className="img-180" alt=""/>
        </div>
      </div>
      <div className="form">
        <div className={"button no-margin"} onClick={props.selectSection}>{ t("ask_for_this_section", {ns: 'buttons'}) }</div>
      </div>
    </div>

  return (
    <div className="controller">
      <div className="controls">
        <div className="action prev" onClick={() => props.onAction("prev")}>
          <Image src="../assets/icons/back-arrow.svg" alt="" isSvg />
          <div className="control-text">{t("prev_table", {ns: 'general'})}</div>
        </div>
        {/* <div className="active-table">
          {
            isLoaded
            ?
              <><div id="current-active-table">{props.currentTable}</div>/<div id="available-tables">{props.availableTables}</div></>
            :
              <Loading small />
          }
        </div> */}
        <div className="min-bottles-badge">
          { props.minBottlesBadge }
        </div>
        <div className="action next" onClick={() => props.onAction("next")}>
          <div className="control-text">{t("next_table", {ns: 'general'})}</div>
          <img src="../assets/icons/back-arrow.svg" className="img-180" alt=""/>
        </div>
      </div>
      <div className="form">
        <div className="personal" data-opened={personalState}>
          <div className="step">
            <div className="step-back" onClick={handleStepBack}>
              <img src="../assets/icons/step-back.svg" alt=""/>
            </div>
            <div className="input-wrapper">
              <div className="label input__label">{t("name_label", {ns: 'inputs'})}</div>
              <input type="text" placeholder={t("name_placeholder", {ns: 'inputs'})} value={userData.name} name="name" onChange={handleInput} />
            </div>
            <div className="input-wrapper">
              <div className="label input__label">{t("contact_numbers_label", {ns: 'inputs'})}</div>
              <TelInput updatePhoneNumber={handlePhoneInput} phone_number={userData.phone}/>
            </div>
          </div>
        </div>
        {
          !props.isInReservationProccess ?
            <div className={"button no-margin" + (isLoaded ? "" : " not-ready")} onClick={handleButtonClick}>{ t(buttonText, {ns: 'buttons'}) }</div>
          :
            <div className="button no-margin not-ready"><Loading small /></div>
        }
        <div className="whatsapp__confirmation__animated-wrapper" data-opened={personalState}>
          <div className="whatsapp__confirmation">
            { t("code_via_whatsapp", { ns: "general" }) }
            <br/><br/>
            <div dangerouslySetInnerHTML={{ __html: t("accept_pp_tof_by_proceeding", { ns: "general" }) }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Component to display reservation status (success or error)
const DisplayReservationStatus = (props: { status: string, shouldSubscribe: () => void, subscribe: () => void, reservationLink: string }) => {
  // translation
  const {t} = useTranslation(['success', 'errors', 'buttons'])
  if(props.status === "success") {
    return (
      <div className="reservation-status">
        <ReservationSuccessIcon />
        <h2 className="reservation-status__h2">{t("booking_success", {ns: 'success'})}</h2>
        <h6 className="reservation-status__h6">{t("booking_message_success", {ns: 'success'})}</h6>
        <h6 className="reservation-status__h6 reservation-status__link"><a href={props.reservationLink} target="_blank">{t("my_booking_link", { ns: 'buttons' })}</a></h6>
        <div className="button no-margin" onClick={() => props.shouldSubscribe()}>{t("next", {ns: 'buttons'})}<ButtonArrow /></div>
      </div>
    )
  } else if(props.status === "failed") {
    return (
      <div className="reservation-status">
        <ReservationErrorIcon />
        <h2 className="reservation-status__h2">{t("booking_unsucess", {ns: 'errors'})}</h2>
        <h6 className="reservation-status__h6">{t("something_wrong", {ns: 'errors'})}</h6>
        <div className="button--back no-margin" onClick={() => window.location.reload()}>{t("back2", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    )
  } else if(props.status === "newsletter") {
    return (
      <div className="reservation-status">
        <PhoneMessageIcon />
        <h2 className="reservation-status__h2">{t("newsletter_title", {ns: 'general'})}</h2>
        <h6 className="reservation-status__h6">{t("newsletter_text", {ns: 'general'})}</h6>
        <div className="reservation-status__grid">
          <div className="button--invert no-margin" onClick={() => window.location.reload()}>{t("dont_want", {ns: 'buttons'})}</div>
          <div className="button no-margin" onClick={() => props.subscribe()}>{t("subscribe", {ns: 'buttons'})}</div>
        </div>
      </div>
    )
  } else if(props.status === "newsletter-done") {
    return (
      <div className="reservation-status">
        <ReservationSuccessIcon />
        <h2 className="reservation-status__h2">{t("subscribe_title_success", {ns: 'success'})}</h2>
        <h6 className="reservation-status__h6">{t("newsletter_subscribe_success", {ns: 'success'})}</h6>
        <div className="button--back no-margin" onClick={() => window.location.reload()}>{t("back2", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    )
  } else if(props.status === "loading") {
    return <div className="reservation-status">
      <Loading />
    </div>
  }
  // default return
  return <></>
}

export default Form;